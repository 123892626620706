
import { createSlice } from '@reduxjs/toolkit';

export interface IProjects {
  departments: any;
  selectedDepartment: any;
  formattedDepartments: any;
  users: any;
  roles: any;
  formattedRoles: any;
  formattedUsers: any;
  units: any;
  selectedUnit: any;
  selectedUser: any;
  organisation: any;
}

const initialState: IProjects = {
  departments: [],
  selectedDepartment: {},
  users: [],
  roles: [],
  formattedRoles: [],
  formattedDepartments: [],
  formattedUsers: [],
  units: [],
  selectedUnit: {},
  selectedUser: {},
  organisation: [],

};

export const settingsReducer = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setDepartments: (state, { payload }) => {
      state.departments = payload;
    },
    setSelectedDepartment: (state, { payload }) => {
      state.selectedDepartment = payload;
    },
    setUsers: (state, { payload }) => {
      state.users = payload;
    },
    setRoles: (state, { payload }) => {
      state.roles = payload;
    },
    setFormattedRoles: (state, { payload }) => {
      state.formattedRoles = payload;
    },
    setFormattedDepartment: (state, { payload }) => {
      state.formattedDepartments = payload;
    },
    setFormattedUsers: (state, { payload }) => {
      state.formattedUsers = payload;
    },
    setUnits: (state, { payload }) => {
      state.units = payload;
    },
    setSelectedUnit: (state, { payload }) => {
      state.selectedUnit = payload;
    },
    setSelectedUser: (state, { payload }) => {
      state.selectedUser = payload
    },
    setOrganisation: (state, { payload }) => {
      state.organisation = payload
    }
  },
});

const { reducer, actions } = settingsReducer;

export const {
  setDepartments, setSelectedDepartment, setUsers, setRoles, setFormattedRoles, setFormattedDepartment, setFormattedUsers, setUnits, setSelectedUnit, setSelectedUser, setOrganisation
} = actions;

export default reducer;