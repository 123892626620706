import React from "react";
import Receiving from "../../../components/reports/generalReports/Receiving";
import DashboardWrapper from "../../../components/DashboardWrapper";
import Breadcrumbs from "../../../parts/Breadcrumbs";

interface Props {
  reports?: string[];
}

const ReceivingReport: React.FC<Props> = ({ reports }) => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title="Receiving Report" />
        
        <ul>
          {reports?.map((report, index) => (
            <li key={index}>{report}</li>
          ))}
        </ul>
        <Receiving />
      </>
    </DashboardWrapper>
  );
};

export default ReceivingReport;
