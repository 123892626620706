import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { errorToastOptions } from "../../constants";
import useUnits from "../../hooks/useUnits";
import { formatProductSelectData } from "../../utils/helpers";
import Input from "./Input";
import InputSelect from "./InputSelect";
import { EllipsisLoader } from "./MyLoaders";
import Textarea from "./Textarea";
import useRequisition from "../../hooks/useRequisition";
import useStockProduct from "../../hooks/useStockProducts";

interface IDynamicFormProps {
  defaultData: Array<any>;
  setValues: (item: Array<any>) => void;
  values: Array<any>;
}

const DynamicForm = ({ defaultData, values, setValues }: IDynamicFormProps) => {
  const handleAddClick = () => {
    setValues([...values, ...defaultData]);
  };

  const handleMinusClick = (index: number) => {
    const newState = values.slice(); // Create a shallow copy of the values array
    if (newState.length === 1) {
      toast.error("You can't remove this column", errorToastOptions);
      return;
    }
    newState.splice(index, 1);
    setValues([...newState]);
  };

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, id } = e.target as HTMLInputElement;
    const newState = [...values]; // Create a copy of the values array
    newState[Number(id)][name] = value;

    if (name === "quantity" || name === "rate") {
      const quantity = parseFloat(newState[Number(id)]["quantity"] || 0);
      const rate = parseFloat(newState[Number(id)]["rate"] || 0);
      newState[Number(id)]["total"] = (quantity * rate).toFixed(2);
    }

    setValues(newState);
  };

  const handleSelectChange = (e: any, id: number) => {
    const { name, value } = e;
    const selectedProduct = products.find(
      (product) => product.id === parseInt(value)
    );
    const newState = [...values]; // Create a copy of the values array
    newState[Number(id)][name] = value;
    newState[Number(id)]["rate"] = selectedProduct
      ? selectedProduct.rate.toString()
      : ""; // Set the rate based on the selected product
    setValues(newState);
  };

  const { loading, units } = useUnits();
  const { getPurchaseOrder, purchaseOrder } = useRequisition();
  const products = [
    {
      name: "Product 1",
      id: 1,
      rate: 1000,
    },
    {
      name: "Product 2",
      id: 2,
      rate: 2000,
    },
    {
      name: "Product 3",
      id: 3,
      rate: 4000,
    },
  ];
  useEffect(() => getPurchaseOrder(), []);
  const { stockProducts } = useStockProduct();

  return loading ? (
    <EllipsisLoader />
  ) : (
    <div className="col-sm-12">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Item</th>
            <th>Quantity</th>
            <th>Rate</th>
            <th>Total</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {values &&
            values.length > 0 &&
            values.map((item, index) => (
              <tr key={index}>
                <td width={"30%"}>
                  <InputSelect
                    label=""
                    handleChange={(e) => handleSelectChange(e, index)}
                    id={index}
                    name={`item_id`}
                    data={formatProductSelectData(stockProducts)}
                    withNoLabel
                    value={values[index].products}
                  />
                </td>
                <td>
                  <Input
                    label=""
                    handleChange={handleChange}
                    id={index}
                    name={`quantity`}
                    noLabel
                  />
                </td>
                <td width={"30%"}>
                  <Input
                    label=""
                    handleChange={handleChange}
                    id={index}
                    name={`rate`}
                    value={values[index].rate} // Display the rate from the state
                    readOnly // Make the field read-only
                    noLabel
                  />
                </td>
                <td width={"30%"}>
                  <Input
                    label=""
                    handleChange={handleChange}
                    id={index}
                    name={`total`}
                    value={values[index].total} // Add value attribute to display total
                    readOnly // Make the field read-only
                    noLabel
                  />
                </td>
                <td>
                  <div className="form-row flex-nowrap">
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        onClick={() => handleMinusClick(index)}
                      >
                        -
                      </button>
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-sm btn-info"
                        onClick={() => handleAddClick()}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicForm;
