import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import { useLastUrlSegment } from '../../hooks/useQuery'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'
import ViewRequisition from '../../components/procurement/requisition/ViewRequisition'

const ViewRequisitionDetails = () => {
  const id = useLastUrlSegment();
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Requisition Details' parents={[{ link: ROUTES.REQUISITIONS, title: "Requisitions" }]} />
        {/* body */}
        <ViewRequisition id={id}/>
      </>
    </DashboardWrapper>
  )
}

export default ViewRequisitionDetails