import { AuthenticatedAPIInstance, APIInstanceUpload } from "../utils/axios";

export const executeDelete = async (path: string) => {
  const response = await AuthenticatedAPIInstance.delete(path).then((response) => response)
    .catch((error) => error);
  return response;
}

export const getAppUserData = async () => {
  const response = await AuthenticatedAPIInstance.get('dashboard').then((response) => response)
    .catch((error) => error);
  return response;
}

export const getAgeGroupUserData = async () => {
  const response = await AuthenticatedAPIInstance.get('dashboard/age_group').then((response) => response)
    .catch((error) => error);
  return response;
}

export const uploadAsset = async (data: any) => {
  const response = await AuthenticatedAPIInstance.post('upload/images', data).then((response) => response)
    .catch((error) => error);
  return response;
}

export const doGet = async (url: string) => {
  const response = await AuthenticatedAPIInstance.get(url).then((response) => response)
    .catch((error) => error);
  return response;
}

export const doCreate = async (url: string, data: any) => {
  const response = await AuthenticatedAPIInstance.post(url, data).then((response) => response)
    .catch((error) => error);
  return response;
}

//APIInstanceUpload
export const upload = async (url: string, data: any) => {
  const response = await APIInstanceUpload.post(url, data).then((response) => response)
    .catch((error) => error);
  return response;
}