import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { stockGet } from '../api/stocks';
import { RootState } from '../redux/app/store';
import { setFormattedStockProducts, setSelectedProduct, setStockProducts } from '../redux/reducer/stocks';
import { formatDepartmentSelectData } from '../utils/helpers';

const useStockProduct = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    stockProducts, formattedStockProducts, selectedProduct
  } = useSelector(
    (state: RootState) => state.stocks,
  );

  useEffect(() => {

    if (stockProducts.length > 0) {
      setLoading(false);
      return;
    }

    stockGet('items').then((response) => {
      if (response.status === 200) {
        dispatch(setStockProducts(response.data.data));
        dispatch(setFormattedStockProducts(formatDepartmentSelectData(response.data.data)));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    setLoading(true);
    stockGet('items').then((response) => {
      if (response.status === 200) {
        dispatch(setStockProducts(response.data.data));
        dispatch(setFormattedStockProducts(formatDepartmentSelectData(response.data.data)));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getProduct = (id: number) => {
    setLoading(true);
    stockGet('items/' + id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedProduct(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return { stockProducts, loading, refresh, formattedStockProducts, selectedProduct, getProduct }
}

export default useStockProduct