import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  ERRORMESSAGE,
  errorToastOptions,
  prchaseOrderDefaultData,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useCommittees from "../../../hooks/useCommittees";
import useRequisition from "../../../hooks/useRequisition";
import {
  formatbidSelectData,
  formatRequisitionSelectData,
  formatStaffSelectData,
  formatSupplierSelectData,
  getFormData,
} from "../../../utils/helpers";
import Validator from "../../../utils/Validator";
import InputDate from "../../global/InputDate";
import InputSelect from "../../global/InputSelect";
import { EllipsisLoader } from "../../global/MyLoaders";
import Textarea from "../../global/Textarea";
import Input from "../../global/Input";
import useSuppliers from "../../../hooks/useSuppliers";

interface IEditPurchaseOrderProps {
  id: number;
  closeModal: () => void;
  refresh: () => void;
  onCancel: () => void;
}

const EditPurchaseOrder = ({ closeModal, id, refresh,onCancel }: IEditPurchaseOrderProps) => {
  const [formLoading, setLoading] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState(prchaseOrderDefaultData);
  const [items, setItems] = useState<any>([]);
  const { loading, getBidAnalysis, bidAnalysis, requisitions, getOrder, order } =
    useRequisition();
  const { approvingCommitte, getApprovingCommitteeMembers } = useCommittees();
  const { suppliers } = useSuppliers();

  const validator = new Validator();
  // eslint-disable-next-line react-hooks/exhaustive-deps

  


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getApprovingCommitteeMembers(), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getBidAnalysis(), []);

  const handleSelected = (e: any) => {
    const { value, name } = e;
    if (value !== "") {
      if (name === "purchase_requisition_id") {
        setItems(
          requisitions.filter((item: any) => item?.id === value)[0].items
        );
        
      }
      // update the state
      setPurchaseOrder({
        ...purchaseOrder,
        ...{
          [name]: { ...purchaseOrder[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setPurchaseOrder({
        ...purchaseOrder,
        ...{
          [name]: { ...purchaseOrder[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    if (value !== "") {
      // update the state
      setPurchaseOrder({
        ...purchaseOrder,
        ...{
          [name]: { ...purchaseOrder[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setPurchaseOrder({
        ...purchaseOrder,
        ...{
          [name]: { ...purchaseOrder[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(purchaseOrder, setPurchaseOrder)) {
      setLoading(true);
      const dataValid = getFormData(purchaseOrder);
      const data = {
        purchase_requisition_id: dataValid.purchase_requisition_id,
        supplier_id: dataValid.supplier_id,
        authorised_by: dataValid.authorised_by,
        delivery_date: dataValid.delivery_date,
        items: items,
        status: "Pending",
        comment: dataValid.comment,
        reference_no: dataValid.reference_no,
      };

      doCreate("purchase_orders", data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success(
              "New Purchase Order has been successfully submitted",
              successToastOptions
            );
            refresh();

            setTimeout(() => {
              closeModal();
            }, 1000);
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .catch(() => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      <div className="form-row">
        <div className="col-sm-4">
          <InputSelect
            data={formatRequisitionSelectData(requisitions)}
            id="purchase_requisition_id"
            label="Requisition"
            name="purchase_requisition_id"
            required
            value={order?.id}
            handleChange={handleSelected}
            // errorMessage="Please select the  requisitions"
            // error={purchaseOrder.purchase_requisition_id.error}
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={formatSupplierSelectData(suppliers)}
            id="supplier_id"
            name="supplier_id"
            label="Supplier"
            handleChange={handleSelected}
            required
            // errorMessage="Please select supplier"
            // value={purchaseOrder.supplier_id.data}
          />
          
        </div>
        <div className="col-sm-4">
          <Input
            name="reference_no"
            label="Ref No"
            handleChange={handleChange}
            required
            value={order.reference_no}
            // errorMessage="Please select reference number"
            // value={purchaseOrder.reference_no.data}
          />
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Supplier</th>
              {/* <th>Remarks</th>
              <th>Unit</th> */}
              <th>Quantity</th>
              <th>Price/Unit</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {items?.map((item: any, index: number) => (
              <tr key={index}>
                <td>{item?.item?.name}</td>
                <td>{item?.supplier_id}</td>
                <td>{item?.quantity}</td>
                <td>{item?.rate}</td>
                <td>{Number(item?.quantity * item?.rate).toLocaleString()}</td>{" "}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="form-row">
        <div className="col-sm-12">
          <Textarea
            label="Comment"
            handleChange={handleChange}
            name="comment"
            error={purchaseOrder.comment.error}
            errorMessage="Please enter some comment about this action"
            required
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect
            data={formatStaffSelectData(approvingCommitte)}
            id="authorised_by"
            label="Authorised by"
            name="authorised_by"
            required
            errorMessage="Please the personal who authorised the action"
            error={purchaseOrder.authorised_by.error}
            handleChange={handleSelected}
          />
        </div>
        <div className="col-sm-6">
          <InputDate
            label="Delivery Date"
            name="delivery_date"
            required
            error={purchaseOrder.delivery_date.error}
            errorMessage="Please select date"
            value={purchaseOrder.delivery_date.data}
            handleChange={handleSelected}
          />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={formLoading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default EditPurchaseOrder;
