import { createSlice } from "@reduxjs/toolkit";

interface ICustomer {
  customers: any;
  selectedCustomer: any;
  requisitions: any;
  requisition: any;
}

const initialState: ICustomer = {
  customers: [],
  selectedCustomer: {},
  requisitions: [],
  requisition: [],
};

export const customerReducer = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomers: (state, { payload }) => {
      state.customers = payload;
    },
    setSelectedCustomer: (state, { payload }) => {
      state.selectedCustomer = payload;
    },
    setRequisitions: (state, { payload }) => {
      state.requisitions = payload;
    },
    setRequisition: (state, { payload }) => {
      state.requisition = payload;
    },
  },
});

const { reducer, actions } = customerReducer;
export const {
  setCustomers,
  setSelectedCustomer,
  setRequisitions,
  setRequisition,
} = actions;
export default reducer;
