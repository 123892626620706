import React from 'react'
import { TableColumn } from 'react-data-table-component';
import useGeoData from '../../../../hooks/useGeoData';
import { padString } from '../../../../utils/helpers';
import { EllipsisLoader } from '../../../global/MyLoaders';
import MyDataTable from '../../../global/MyDataTable';

interface IVillageTableProps {
  handleEditClick: (id: number) => void;
  handleDeleteClick: (id: number) => void;
}

const VillageTable = ({ handleDeleteClick, handleEditClick }: IVillageTableProps) => {
  const { loading, _villages } = useGeoData();
  console.log(_villages);
  const villageTableColumns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row) => row.id,
      reorder: true,
      sortable: true
    }, {
      id: 2,
      name: "Name",
      selector: (row) => row.name,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, {
      id: 3,
      name: "Parish",
      selector: (row) => row.parish.name,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },

    {
      button: true,
      cell: (row) => (
        <div className='row'>
          {/* <button className='btn btn-sm btn-primary mx-1'><i className='fa fa-eye'></i></button> */}
          <button className='btn btn-sm btn-primary'><i className='fa fa-edit' onClick={(e) => {
            e.stopPropagation();
            // handleEditClick(row.id);
          }}></i></button>

          <button className='btn btn-sm btn-danger mx-1' onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(row.id);
          }}><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];
  return loading ? <EllipsisLoader /> : (
    <MyDataTable data={_villages} columns={villageTableColumns} title="" />
  )
}

export default VillageTable