import React from "react";
import { ITabsData, MyTabs } from "../../global/MyTab";
import ListVillages from "./villages/ListVillages";
import ListParishes from "./parishes/ListParishes";
import ListSubCounties from "./subCounties/ListSubCounties";
import ListCounties from "./counties/ListCounties";
import ListDistricts from "./districts/ListDistricts";

const LocationTabs = () => {
    const ApplicationsTabsData: ITabsData[] = [
        {
          tabId: 1,
          title: 'Villages',
          component: <ListVillages />,
        },
        {
            tabId: 2,
            title: 'Parishes',
            component: <ListParishes />,
          },
          {
            tabId: 3,
            title: 'Sub Counties',
            component: <ListSubCounties />,
          },
          {
            tabId: 4,
            title: 'Counties',
            component: <ListCounties />,
          },
          {
            tabId: 5,
            title: 'Districts',
            component: <ListDistricts />,
          },
        
    
      ];
  return (
    <div className="bg-white border-b-2">
      <MyTabs TabsData={ApplicationsTabsData} />
    </div>
  );
};

export default LocationTabs;
