import { AuthenticatedAPIInstance } from "../../../utils/axios";

export const getAllDepartments = async () => {
  const response = await AuthenticatedAPIInstance.get("departments")
    .then((response) => response)
    .catch((error) => error);
  return response;
};

export const getAllUsers = async () => {
  const response = await AuthenticatedAPIInstance.get("users")
    .then((response) => response)
    .catch((error) => error);
  return response;
};

export const getAllRoles = async () => {
  const response = await AuthenticatedAPIInstance.get("roles")
    .then((response) => response)
    .catch((error) => error);
  return response;
};

export const createDepartment = async (data: any) => {
  const response = await AuthenticatedAPIInstance.post(
    "departments/create",
    data
  )
    .then((response) => response)
    .catch((error) => error);
  return response;
};

export const createUser = async (data: any) => {
  const response = await AuthenticatedAPIInstance.post("users/create", data)
    .then((response) => response)
    .catch((error) => error);
  return response;
};

export const updateDepartment = async (data: any, id: number) => {
  const response = await AuthenticatedAPIInstance.post(
    `departments/${id}/update`,
    data
  )
    .then((response) => response)
    .catch((error) => error);
  return response;
};

export const getDepartment = async (id: number) => {
  const response = await AuthenticatedAPIInstance.get(`departments/${id}`)
    .then((response) => response)
    .catch((error) => error);
  return response;
};

export const createVillage = async (data: any) => {
  const response = await AuthenticatedAPIInstance.post("villages", data)
    .then((response) => response)
    .catch((error) => error);
  return response;
};

export const createParish = async (data: any) => {
  const response = await AuthenticatedAPIInstance.post("parishes", data)
    .then((response) => response)
    .catch((error) => error);
  return response;
};

export const createSubCounty = async (data: any) => {
  const response = await AuthenticatedAPIInstance.post("subcounties", data)
    .then((response) => response)
    .catch((error) => error);
  return response;
};
export const createCounty = async (data: any) => {
  const response = await AuthenticatedAPIInstance.post("counties", data)
    .then((response) => response)
    .catch((error) => error);
  return response;
};
export const createDistrict = async (data: any) => {
  const response = await AuthenticatedAPIInstance.post("districts", data)
    .then((response) => response)
    .catch((error) => error);
  return response;
};
