import React, { useEffect, useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import ReactToPrint from "react-to-print";
import useRequisition from "../../../hooks/useRequisition";
import { EllipsisLoader } from "../../global/MyLoaders";
import useOrganisation from "../../../hooks/useOrganisation";
import Logo from "../../../assets/images/logo.svg"

interface PurchaseOrder {
  id: any;
}

const ViewPurchaseOrder = ({ id }: PurchaseOrder) => {
  const [totalAmount, setTotalAmount] = useState(0);
  const handlePrint = () => {
    window.print();
  };
  const componentRef = React.useRef(null);

  const {organisation} = useOrganisation();

  const { getOrder, order, loading } = useRequisition();
  useEffect(() => getOrder(id), []);
  useEffect(() => {
    if (order?.items) {
      let total = 0;
      order.items.forEach((item: any) => {
        total += Number(item.total);
      });
      setTotalAmount(total);
    }
  }, [order]);
  return loading ? (
    <EllipsisLoader />
  ) : (
    <div className="col-xl-12 mb-30">
      <div className="card card-statistics h-100">
        <div className="card-body">
          <div className="d-block d-md-flex justify-content-end mb-10">
            
            <div className="d-block d-md-flex clearfix sm-mt-20">
              <div className="widget-search ml-0 clearfix">
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-primary ml-auto mr-3">Print Order</button>
                  )}
                  content={() => componentRef.current}
                />
              </div>
            </div>
          </div>
          <div className="container" ref={componentRef}>
            <div className="row">
              <div className="col">
              <img src={Logo} alt="" className="h-8" />
                <h5>{organisation?.name}</h5>
                <p>{organisation?.email}</p>
                <p>{organisation?.phone}</p>
              </div>
              <div className="col text-right">
                <h2>Purchase Order</h2>
                <p>
                  <strong>Order ID:</strong> {order.reference_no}
                </p>
                <p>
                  <strong>Date:</strong> {order.delivery_date}
                </p>
              </div>
            </div>
            <hr />
            <br />
            <div className="row">
            <div className="col">
                <h4>VENDOR:</h4>
                <p>
                  <strong>Name:</strong> {order.supplier?.name}
                </p>
                <p>
                  <strong>Address:</strong> {order.supplier?.location}
                </p>
                <p>
                  <strong>Email:</strong> {order.supplier?.email}
                </p>
                <p>
                  <strong>Tel:</strong> {order.supplier.telephone}
                </p>
              </div>
              <div className="col">
                <h4>SHIP TO:</h4>
                <p>
                  <strong>Name:</strong>{organisation?.name}
                </p>
                <p>
                  <strong>Address:</strong> {"123 Main St, City, Country"}
                </p>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Item Name</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order?.items?.map((item:any, index: number) => (
                      <tr key={index}>
                        <td>{item?.item?.name}</td>
                        <td>{item?.quantity}</td>
                        <td>{item?.quantity}</td>
                        <td>{item?.rate}</td>
                        <td>{Number(item?.quantity * item?.rate).toLocaleString()}</td>
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="text-left">
                        <strong>Sub Total:</strong>{" "}
                      </td>
                      <td>{Number(totalAmount).toLocaleString()}</td>
                    </tr>
                    <tr>
                      <td className="border border-0"></td>
                      <td className="border border-0"></td>
                      <td className="border border-0"></td>
                      <td>
                        <strong>Tax Rate:</strong>
                      </td>

                      <td></td>
                    </tr>
                    <tr>
                      <td className="border border-0"></td>
                      <td className="border border-0"></td>
                      <td className="border border-0"></td>
                      <td>
                        <strong>Sales Tax:</strong>
                      </td>

                      <td></td>
                    </tr>
                    <tr>
                      <td className="border border-0"></td>
                      <td className="border border-0"></td>
                      <td className="border border-0"></td>
                      <td>
                        <strong>Shipping and Handling:</strong>
                      </td>

                      <td></td>
                    </tr>
                    <tr>
                      <td className="border border-0"></td>
                      <td className="border border-0"></td>
                      <td className="border border-0"></td>
                      <td>
                        <strong>Others:</strong>
                      </td>

                      <td></td>
                    </tr>
                    <tr>
                      <td className="border border-0"></td>
                      <td className="border border-0"></td>
                      <td className="border border-0"></td>
                      <td>
                        <strong>Total:</strong>
                      </td>
                      <td>{Number(totalAmount).toLocaleString()}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mt-10">
              <div className="col text-right border-right border-dark">
                <h5 className="">Thank you</h5>
              </div>
              <div className="col">
                <h4 className="text-danger">TERMS & CONDITIONS</h4>
                <p className="mb-4">Payment is  due in 2 days</p>
                <p className="">Bank Name: Testing Bank</p>
                <p className="">Account No: 123456789</p>
                <p className="">Branch: Test Branch</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPurchaseOrder;
