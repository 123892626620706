import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { errorToastOptions } from "../../constants";
import Input from "./Input";
import useCustomers from "../../hooks/useCustomers";
import InputSelect from "./InputSelect";
import { formatRequisitionSelectData } from "../../utils/helpers";

interface IDynamicFormProps {
  defaultData: Array<any>;
  setValues: (item: Array<any>) => void;
  values: Array<any>;
  setItems: (item: Array<any>) => void;
  items: any;
}

const DynamicInvoiceForm = ({
  defaultData,
  values,
  setValues,
  setItems,
  items,
}: IDynamicFormProps) => {
  const [useDynamicForm, setUseDynamicForm] = useState<boolean>(false);
  // const [items, setItems] = useState<any>([]);

  const { getRequisitions, requisitions, loading } = useCustomers();
  useEffect(() => getRequisitions(), []);

  const handleAddClick = () => {
    setValues([...values, ...defaultData]);
  };

  const handleMinusClick = (index: number) => {
    const newState = values.slice();
    if (newState.length === 1) {
      toast.error("You can't remove this column", errorToastOptions);
      return;
    }
    newState.splice(index, 1);
    setValues([...newState]);
  };
  const handleSelected = (e: any) => {
    const { value, name } = e;
    if (value !== "") {
      if (name === "purchase_requisition_id") {
        setItems(requisitions.filter((item: any) => item?.id === value)[0]['items']);
      }
      setValues({
        ...values,
        ...{
          [name]: { ...values[name], ...{ error: false, data: value } },
        },
        ...items,
      });
    } else {
      setValues({
        ...values,
        ...{
          [name]: { ...values[name], ...{ error: true, data: value } },
        },
        ...items,
      });
    }
  };

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, id } = e.target as HTMLInputElement;
    const newState = values.slice();
    newState[Number(id)][name] = value;
    newState[Number(id)].sub_total =
      newState[Number(id)].quantity * newState[Number(id)].amount;
    setValues([...newState]);
  };

  const handleToggleForm = () => {
    setUseDynamicForm(!useDynamicForm);
    if (!useDynamicForm) {
      setValues(defaultData);
    } else {
      setValues([]);
    }
  };

  return (
    <div className="col-sm-12">
      {useDynamicForm ? (
        <button
          type="button"
          className="btn btn-sm btn-info mb-2"
          onClick={() => handleToggleForm()}
        >
          Select Products from Customer Requests
        </button>
      ) : (
        <button
          type="button"
          className="btn btn-sm btn-info mb-2"
          onClick={() => handleToggleForm()}
        >
          Enter product
        </button>
      )}

      {useDynamicForm && (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th colSpan={3}>Product Name</th>
              <th>Amount</th>
              <th>Quantity</th>
              <th>Sub Total</th>
            </tr>
          </thead>
          <tbody>
            {values.map((item, index) => (
              <tr key={index}>
                <td colSpan={3}>
                  <Input
                    label=""
                    handleChange={handleChange}
                    id={index}
                    name={`product_name`}
                    noLabel
                  />
                </td>
                <td>
                  <Input
                    type="number"
                    label=""
                    handleChange={handleChange}
                    id={index}
                    name={`amount`}
                    noLabel
                  />
                </td>
                <td>
                  <Input
                    type="number"
                    label=""
                    handleChange={handleChange}
                    id={index}
                    name={`quantity`}
                    noLabel
                  />
                </td>
                <td>
                  <div className="form-row flex-nowrap justify-content-center align-items-center">
                    <div className="col">
                      {item["quantity"] * item["amount"]}
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        onClick={() => handleMinusClick(index)}
                      >
                        -
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!useDynamicForm && (
        <div>
          {/* Display items from default data */}
          <div className="form-row">
            <div className="col-sm-4">
              <InputSelect
                data={formatRequisitionSelectData(requisitions)}
                id="purchase_requisition_id"
                label="Customer Requests"
                name="purchase_requisition_id"
                required
                handleChange={handleSelected}
                // errorMessage="Please select the requisitions"
                // error={purchaseOrder.purchase_requisition_id.error}
              />
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Price/Unit</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {items?.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{item?.item?.name}</td>
                    <td>{item?.quantity}</td>
                    <td>{item?.rate}</td>
                    <td>
                      {Number(item?.quantity * item?.rate).toLocaleString()}
                    </td>{" "}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {useDynamicForm && (
        <div className="col mx-0 my-2">
          <button
            type="button"
            className="btn btn-sm btn-info"
            onClick={() => handleAddClick()}
          >
            Add +
          </button>
        </div>
      )}
    </div>
  );
};

export default DynamicInvoiceForm;
