import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useCustomers from "../../../hooks/useCustomers";
import Validator from "../../../utils/Validator";
import {
  customerDefaultData,
  errorToastOptions,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import { EllipsisLoader } from "../../global/MyLoaders";
import Input from "../../global/Input";
import Textarea from "../../global/Textarea";
import { editCustomer } from "../../../api/customers";

interface CreateCustomerProps {
  closeModal: () => void;
  id: number;
}

const EditCustomer = ({ closeModal, id }: CreateCustomerProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [customer, setCustomer] = useState(customerDefaultData);
  const { refresh, getSelectedCustomer, selectedCustomer, loading } =
    useCustomers();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedCustomer(id), [id]);

  const formNames = [
    "name",
    "email",
    "phone_no",
    "alt_phone",
    "debt_limit",
    "address",
  ];

  useEffect(() => {
    let customerData = customerDefaultData;
    if (!loading) {
      for (let x of formNames) {
        customerData = {
          ...customerData,
          ...{
            [x]: {
              ...customerData[x],
              ...{ error: false, data: selectedCustomer[x] },
            },
          },
        };
      }
      setCustomer(customerData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const validator = new Validator();

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;

    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setCustomer({
        ...customer,
        ...{ [name]: { ...customer[name], ...{ error: false, data: value } } },
      });
    } else {
      // indicate an error
      setCustomer({
        ...customer,
        ...{ [name]: { ...customer[name], ...{ error: true, data: value } } },
      });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(customer, setCustomer)) {
      setFormLoading(true);
      const data = {
        name: customer.name.data,
        phone_no: customer.phone_no.data,
        email: customer.email.data,
        alt_phone: customer.alt_phone.data,
        address: customer.address.data,
        debt_limit: customer.debt_limit.data,
      };
      editCustomer(id, data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success("Customer updated successfully", successToastOptions);
            refresh();

            setTimeout(() => {
              closeModal();
            }, 1000);
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .finally(() => {
          setFormLoading(false);
        });
    }
  };

  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input
            handleChange={handleChange}
            name="name"
            label="Customer name"
            value={customer.name.data}
            error={customer.name.error}
            errorMessage="Please enter the customer name"
            required
          />
        </div>
        <div className="col-sm-6">
          <Input
            handleChange={handleChange}
            name="email"
            type="email"
            label="Email Address"
            value={customer.email.data}
            error={customer.email.error}
            errorMessage="Please enter the email"
            required
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Input
            handleChange={handleChange}
            name="phone_no"
            label="Phone No"
            value={customer.phone_no.data}
            error={customer.phone_no.error}
            errorMessage="Please enter the customer phone number"
            required
          />
        </div>
        <div className="col-sm-6">
          <Input
            handleChange={handleChange}
            name="alt_phone"
            label="Alternative Phone No"
            value={customer.alt_phone.data}
            error={customer.alt_phone.error}
            errorMessage="Please enter the alt phone number"
            required
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input
            type="number"
            handleChange={handleChange}
            name="debt_limit"
            label="Debit Limit"
            required
            value={customer.debt_limit.data}
            error={customer.debt_limit.error}
            errorMessage="Please enter the debit limit value"
          />
        </div>
        <div className="col-sm-6">
          <Textarea
            handleChange={handleChange}
            name="address"
            label="Address"
            value={customer.address.data}
            error={customer.address.error}
            errorMessage="Please enter the address"
            required
          />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={formLoading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default EditCustomer;
