import React, { useState } from 'react'
import useDepartments from '../../../../hooks/useDepartments';
import EditDepartment from '../../departments/EditDepartment';
import ConfirmDelete from '../../../global/ConfirmDelete';
import ModalSwitcher from '../../../global/ModalSwitcher';
import AddCounty from './AddCounty';
import CountyTable from './CountyTable';

const ListCounties = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const { refresh } = useDepartments();
  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }

  const handleDeleteClick = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = 'edit-department';
      modalTitle = 'Edit County';
      ModalToRender = EditDepartment

      break;
    case 3:
      modalId = 'delete-county';
      modalTitle = '';
      ModalToRender = ConfirmDelete
      break;
    default:
      modalId = 'add-county';
      modalTitle = 'Add County';
      ModalToRender = AddCounty;
      break;
  }

  return (
    <>
      <ModalSwitcher isOpen={isOpen} setIsOpen={setIsOpen} id={modalId} title={modalTitle} component={<ModalToRender id={selectedId} closeModal={handleModalClick} path={`departments/${selectedId}/delete`} refresh={refresh} />} />

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className='pl-3 row align-items-end'>
                  <button className='btn btn-primary ml-auto mr-3'
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenModal(0);
                      setIsOpen(true);
                    }}><i className='fa fa-plus mx-2'></i>Add County</button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input type="search" className="form-control" placeholder="Search...." />
                </div>
              </div>
            </div>
            {/* table */}
            <CountyTable handleDeleteClick={handleDeleteClick} handleEditClick={handleEditClick} />
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ListCounties