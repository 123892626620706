import React from 'react'
import Organisation from '../../components/settings/organisation/Organisation'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const OrganisationSettings = () => {
  return (
    <SettingsWrapper>
      <>
        <Breadcrumbs title='Organisation' />
        {/* body */}
        <Organisation />
      </>
    </SettingsWrapper>

  )
}

export default OrganisationSettings