import React from 'react'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import LocationTabs from '../../components/settings/locations/LocationTabs'

const Locations = () => {
  return (
    <SettingsWrapper>
      <>
        <Breadcrumbs title='Location Settings' />
        {/* body */}
        <LocationTabs />
      </>
    </SettingsWrapper>

  )
}

export default Locations