import { createSlice } from "@reduxjs/toolkit";

interface IAccounting {
  accountCategories: any;
  formattedAccountingCategories: any;
  accounts: any;
  requisitions: any;
  requisition: any;
  incomeAccounts: any;
  formattedIncomeAccounts: any;
  assetAccounts: any;
  formattedAssetAccounts: any;
  expenseAccounts: any;
  formattedExpenseAccounts: any;
  formattedIncomeTypes: any;
  formattedExpenseTypes: any;
  transactions: any;
  incomeStatement: any;
  incomeStatementExpense: any;
  balanceSheet: any;
  balanceSheetLialibility: any;
  selectedAccount: any;
  budgets: any;
  selectedBudget: any;
  cashRequisition: any;
  bills: any;
  selectedBills: any;
  cashAccounts: any;
  bidAnalysis: any;
  purchaseOrder: any;
  order: any;
  goodsRecieved: any;
  selectedGood: any;
  invoices: any;
  selectedInvoice: any;
  paymentTypes: any;
  selectedPaymentType: any;
  selectedTransaction: any;
  selectedAccountDetails: any;
  selectedBill: any;
  selectedCashRequisition: any;
  trailBalance: any;
  budgetComparision: any;
  budgetComparisionDetails: any;
  subCategories: any;
  categories: any;
  folderView: any;
  dailySummation: any;
  schedules: any;
  comparisonReport: any;
  ledgerReportCategory: any;
  cashFlow: any;
}

const initialState: IAccounting = {
  accountCategories: [],
  formattedAccountingCategories: [],
  accounts: [],
  requisitions: [],
  requisition: [],
  incomeAccounts: [],
  formattedIncomeAccounts: [],
  assetAccounts: [],
  formattedAssetAccounts: [],
  expenseAccounts: [],
  formattedExpenseAccounts: [],
  formattedIncomeTypes: [],
  formattedExpenseTypes: [],
  transactions: [],
  incomeStatement: [],
  incomeStatementExpense: [],
  balanceSheet: [],
  balanceSheetLialibility: [],
  budgets: [],
  selectedBudget: {},
  selectedAccount: {},
  cashRequisition: [],
  selectedCashRequisition: {},
  bills: [],
  selectedBills: {},
  cashAccounts: [],
  bidAnalysis: [],
  purchaseOrder: [],
  order: [],
  goodsRecieved: [],
  selectedGood: [],
  invoices: [],
  selectedInvoice: {},
  paymentTypes: [],
  selectedPaymentType: {},
  selectedTransaction: {},
  selectedAccountDetails: {},
  selectedBill: {},
  trailBalance: [],
  budgetComparision: [],
  budgetComparisionDetails: {},
  subCategories: [],
  categories: [],
  folderView: [],
  dailySummation: [],
  schedules: {},
  comparisonReport: {},
  ledgerReportCategory: [],
  cashFlow: {},
};

const accountingReducer = createSlice({
  name: "accounting",
  initialState,
  reducers: {
    setAccountingCategories: (state, { payload }) => {
      state.accountCategories = payload;
    },
    setFormattedAccountingCategories: (state, { payload }) => {
      state.formattedAccountingCategories = payload;
    },
    setAccounts: (state, { payload }) => {
      state.accounts = payload;
    },
    setRequisitions: (state, { payload }) => {
      state.requisitions = payload;
    },
    setRequisition: (state, { payload }) => {
      state.requisition = payload;
    },
    setIncomeAccount: (state, { payload }) => {
      state.incomeAccounts = payload;
    },
    setFormattedIncomeAccounts: (state, { payload }) => {
      state.formattedIncomeAccounts = payload;
    },
    setExpenseAccount: (state, { payload }) => {
      state.expenseAccounts = payload;
    },
    setFormattedExpenseAccounts: (state, { payload }) => {
      state.formattedExpenseAccounts = payload;
    },
    setAssetAccount: (state, { payload }) => {
      state.assetAccounts = payload;
    },
    setFormattedAssetAccounts: (state, { payload }) => {
      state.formattedAssetAccounts = payload;
    },
    setFormattedIncomeType: (state, { payload }) => {
      state.formattedIncomeTypes = payload;
    },
    setFormattedExpenseType: (state, { payload }) => {
      state.formattedExpenseTypes = payload;
    },
    setTransactions: (state, { payload }) => {
      state.transactions = payload;
    },
    setIncomeStatement: (state, { payload }) => {
      state.incomeStatement = payload;
    },
    setIncomeStatementExpense: (state, { payload }) => {
      state.incomeStatementExpense = payload;
    },
    setBalanceSheet: (state, { payload }) => {
      state.balanceSheet = payload;
    },
    setBalanceSheetLialibilty: (state, { payload }) => {
      state.balanceSheetLialibility = payload;
    },
    setBudgets: (state, { payload }) => {
      state.budgets = payload;
    },
    setSelectedBudget: (state, { payload }) => {
      state.selectedBudget = payload;
    },
    setSelectedAccount: (state, { payload }) => {
      state.selectedAccount = payload;
    },
    setCashRequisition: (state, { payload }) => {
      state.cashRequisition = payload;
    },
    setBills: (state, { payload }) => {
      state.bills = payload;
    },
    setSelectedBills: (state, { payload }) => {
      state.selectedBills = payload;
    },
    setCashAccount: (state, { payload }) => {
      state.cashAccounts = payload;
    },
    setBidAnalysis: (state, { payload }) => {
      state.bidAnalysis = payload;
    },
    setPurchaseOrder: (state, { payload }) => {
      state.purchaseOrder = payload;
    },
    setOrder: (state, { payload }) => {
      state.order = payload;
    },
    setGoodsReceived: (state, { payload }) => {
      state.goodsRecieved = payload;
    },
    setSelectedGood: (state, { payload }) => {
      state.selectedGood = payload;
    },
    setInvoices: (state, { payload }) => {
      state.invoices = payload;
    },
    setSelectedInvoice: (state, { payload }) => {
      state.selectedInvoice = payload;
    },
    setPaymentTypes: (state, { payload }) => {
      state.paymentTypes = payload;
    },
    setSelectedPaymentType: (state, { payload }) => {
      state.selectedPaymentType = payload;
    },
    setSelectedTransaction: (state, { payload }) => {
      state.selectedTransaction = payload;
    },
    setSelectedAccountDetails: (state, { payload }) => {
      state.selectedAccountDetails = payload;
    },
    setSelectedBill: (state, { payload }) => {
      state.selectedBill = payload;
    },
    setSelectedCashRequisition: (state, { payload }) => {
      state.selectedCashRequisition = payload;
    },
    setTrailBalance: (state, { payload }) => {
      state.trailBalance = payload;
    },
    setBudgetComparision: (state, { payload }) => {
      state.budgetComparision = payload;
    },
    setBudgetComparisionDetails: (state, { payload }) => {
      state.budgetComparisionDetails = payload;
    },
    setSubCategories: (state, { payload }) => {
      state.subCategories = payload;
    },
    setCategories: (state, { payload }) => {
      state.categories = payload;
    },
    setFolderView: (state, { payload }) => {
      state.folderView = payload;
    },
    setDailySummation: (state, { payload }) => {
      state.dailySummation = payload;
    },
    setSchedules: (state, { payload }) => {
      state.schedules = payload;
    },
    setComparisonReport: (state, { payload }) => {
      state.comparisonReport = payload;
    },
    setLedgerReportCategory: (state, { payload }) => {
      state.ledgerReportCategory = payload;
    },
    setCashFlow: (state, { payload }) => {
      state.cashFlow = payload;
    },
  },
});

const { actions, reducer } = accountingReducer;

export const {
  setAccountingCategories,
  setFormattedAccountingCategories,
  setAccounts,
  setRequisitions,
  setRequisition,
  setIncomeAccount,
  setFormattedIncomeAccounts,
  setAssetAccount,
  setExpenseAccount,
  setFormattedAssetAccounts,
  setFormattedExpenseAccounts,
  setFormattedIncomeType,
  setFormattedExpenseType,
  setTransactions,
  setIncomeStatement,
  setBalanceSheet,
  setBalanceSheetLialibilty,
  setIncomeStatementExpense,
  setBudgets,
  setSelectedBudget,
  setSelectedAccount,
  setCashRequisition,
  setBills,
  setSelectedBills,
  setCashAccount,
  setBidAnalysis,
  setPurchaseOrder,
  setOrder,
  setGoodsReceived,
  setSelectedGood,
  setInvoices,
  setSelectedInvoice,
  setPaymentTypes,
  setSelectedPaymentType,
  setSelectedTransaction,
  setSelectedAccountDetails,
  setSelectedBill,
  setSelectedCashRequisition,
  setTrailBalance,
  setBudgetComparision,
  setBudgetComparisionDetails,
  setSubCategories,
  setCategories,
  setFolderView,
  setDailySummation,
  setSchedules,
  setComparisonReport,
  setLedgerReportCategory,
  setCashFlow,
} = actions;
export default reducer;
