import { useState } from "react";
import toast from "react-hot-toast";
import {
  addDistrictDefaultData,
  ERRORMESSAGE,
  errorToastOptions,
  successToastOptions,
} from "../../../../constants";
import Validator from "../../../../utils/Validator";
import { formatErrorMessage } from "../../../../helpers";
import Input from "../../../global/Input";
import InputSelect from "../../../global/InputSelect";
import useGeoData from "../../../../hooks/useGeoData";
import { formatSelectData } from "../../../../utils/helpers";
import { createDistrict } from "../../../../api/settings/geoLocation";
import { EllipsisLoader } from "../../../global/MyLoaders";

interface IDistrictProps {
  closeModal: () => void;
}

const AddDistrict = ({ closeModal }: IDistrictProps) => {
  const [district, setDistrict] = useState(addDistrictDefaultData);
  const [isLoading, setIsLoading] = useState(false);
  const validator = new Validator();
  const { countries, loading, refreshData } =
    useGeoData();

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    if (value !== "") {
      // update the state
      setDistrict({
        ...district,
        ...{ [name]: { ...district[name], error: false, data: value } },
      });
    } else {
      // indicate an error
      setDistrict({
        ...district,
        ...{ [name]: { ...district[name], error: true, data: value } },
      });
    }
  };

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setDistrict({ ...district, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setDistrict({ ...district, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(district, setDistrict)) {
      setIsLoading(true);

      const data = {
        name: district.name.data,
        country_id: district.country_id.data,
      };

      try {
        const response = await createDistrict(data);
        if (response.data.success === true) {
          toast.success(
            "New District created successfully",
            successToastOptions
          );
          setTimeout(() => {
            closeModal();
            refreshData();
          }, 1000);
        } else {
          toast.error(
            formatErrorMessage(response.data.data, response.data.message),
            errorToastOptions
          );
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input
            name="name"
            label="District Name"
            handleChange={handleChange}
            error={district?.name.error}
            errorMessage="Please provide a district name"
            value={district?.name.data}
            required
          />
        </div>
        
        <div className="col-sm-6">
          <InputSelect
            data={formatSelectData(countries)}
            id="country_id"
            label="Country"
            name="country_id"
            handleChange={(e: any) => {
              handleSelectChange(e);
            }}
            value={district?.country_id.data}
            error={district?.country_id.error}
            errorMessage="Please provide the Country"
            required
          />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isLoading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {isLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default AddDistrict;
