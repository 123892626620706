import React, { useEffect, useState } from 'react'
import Input from '../../global/Input'
import ImagePicker from "../../global/ImagePicker";
import "./organisation.css";
import Card from '../../../parts/Card';
// import MyModal from "../../global/MyModal";
// import ChangePassword from "./ChangePassword";
import { editOrganisationData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import Validator from '../../../utils/Validator';
import { doCreate } from '../../../api';
import toast from 'react-hot-toast';
import { formatErrorMessage } from '../../../helpers';
import { updateSession } from '../../../utils/helpers';
import { baseURLImage } from '../../../utils/axios';
import useOrganisation from '../../../hooks/useOrganisation';
import { EllipsisLoader } from '../../global/MyLoaders';

const Organisation = () => {
//   const [organisation] = useState(JSON.parse(sessionStorage.organisation)?.organisation);
  const {loading, refresh, organisation} = useOrganisation();
  const [selectedOrganisation, setSelectedOrganisation] = useState(editOrganisationData);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  console.log(organisation);

  const validator = new Validator();

  useEffect(() => {
    // if(!loading && !organisation) return <EllipsisLoader />;
    const formNames = Object.keys(organisation).map((data) => data);

    let organisationData = editOrganisationData;
    for (let x of formNames) {
      organisationData = { ...organisationData, ...{ [x]: { ...organisationData[x], ...{ error: false, data: organisation[x] } } } };
    }
    setSelectedOrganisation(organisationData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!loading]);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setSelectedOrganisation({ ...selectedOrganisation, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setSelectedOrganisation({ ...selectedOrganisation, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleImageChange = (e: any) => {
    setSelectedOrganisation({ ...selectedOrganisation, ...{ profile_pic: { error: false, data: e } } });

    // update session
    updateSession({ profile_pic: baseURLImage + "" + e });

  }


  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(selectedOrganisation, setSelectedOrganisation)) {
      setIsLoading(true);

      const data = {
        name: selectedOrganisation.name.data,
        phone: selectedOrganisation.phone.data,
        email: selectedOrganisation.email.data,
      }

      try {
        const response = await doCreate('users/' + organisation?.id + '/update_account', data);
        if (response.data.success === true) {
          toast.success('Your details have been updated successfully', successToastOptions);
          refresh(); // refresh the hook store

        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setIsLoading(false)
      }
    }
  }

  return loading ? <EllipsisLoader /> : (
    <>
      
      <Card>
        <>
          <div className="p-6 border-b border-slate-200">

            <section>
              <ImagePicker handleChange={handleImageChange} imageUrl={selectedOrganisation.logo.data} />
            </section>

            <section>
              <h5 className="text-slate-800 font-bold text-xl mb-1">Details</h5>
              <p className="text-sm">
                From here you can updateorganisation details.{" "}
              </p>
              <div className="row mx-0 mt-5">
                {/* inputs */}
                <div className="col-12">
                  <Input handleChange={handleChange} label="Organisation Name" name="name" value={selectedOrganisation.name.data} error={selectedOrganisation.name.error} errorMessage="Please enter the name" noLabel />
                </div>
                <div className="col-6">
                  <Input handleChange={handleChange} label="Email" name="email" noLabel value={selectedOrganisation.email.data} error={selectedOrganisation.email.error} errorMessage="Please enter the email" />
                </div>
                <div className="col-6">
                  <Input handleChange={handleChange} label="Mobile number" name="phone" noLabel value={selectedOrganisation.phone.data} error={selectedOrganisation.phone.error} errorMessage="Please enter the telephone number" />
                </div>
                {/* end inputs */}
              </div>
            </section>

          </div>
          <hr />

          <div className="px-3 py-4">
            <div className="row mx-0">
              <button className="btn btn-primary ml-auto" disabled={isLoading} onClick={handleSubmit}>
                {isLoading ? "Saving..." : "Update"}
              </button>
            </div>
          </div>
        </>
      </Card>
    </>
  )
}

export default Organisation