import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import ListRequisitions from '../../components/cunstomers/requisitions/ListRequisitions'

const CustomerRequisition = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Customer Requisitions' />
        {/* body */}
        <ListRequisitions />
      </>
    </DashboardWrapper>

  )
}

export default CustomerRequisition