import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doGet } from "../api";
import { RootState } from "../redux/app/store";
import {
  setBidAnalysis,
  setGoodsReceived,
  setPurchaseOrder,
  setRequisitions,
  setRequisition,
  setOrder,
  setSelectedGood,
} from "../redux/reducer/accounting";

const useRequisition = (status?: string) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  // const URL = state ? `requisitions?state=${state}` : "requisitions";
  const URL = status ? `purchase_requisitions?status=${status}` : "purchase_requisitions";

  const { requisitions, bidAnalysis, purchaseOrder, goodsRecieved, requisition, order, selectedGood } =
    useSelector((state: RootState) => state.accounting);

  useEffect(() => {
    // if (requisitions.length > 0 && !state) {
    //   return;
    // }

    setLoading(true);
    doGet(URL)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setRequisitions(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const refresh = () => {
    setLoading(true);
    doGet(URL)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setRequisitions(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getBidAnalysis = (status?: number) => {
    const URL = status ? "bid-analysis?status=" + status : "bid-analysis";
    // if (bidAnalysis.length > 0) {
    //   setLoading(false);
    //   return;
    // }
    setLoading(true);
    doGet(URL)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setBidAnalysis(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPurchaseOrder = (status?: number) => {
    const URL = status ? "purchase_orders?status=" + status : "purchase_orders";
    // if (purchaseOrder.length > 0) {
    //   setLoading(false);
    //   return;
    // }
    setLoading(true);
    doGet(URL)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setPurchaseOrder(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRequisition = (req_id?: number) => {
    const URL = "purchase_requisitions/" + req_id;
    setLoading(true);
    doGet(URL)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setRequisition(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getOrder = (order_id?: number) => {
    const URL = "purchase_orders/" + order_id;
    setLoading(true);
    doGet(URL)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setOrder(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSelectedGood = (good_id?: number) => {
    const URL = "grns/" + good_id;
    setLoading(true);
    doGet(URL)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setOrder(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const getGoodsRecieved = () => {
    setLoading(true);
    doGet("grns")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setGoodsReceived(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    requisitions,
    requisition,
    loading,
    refresh,
    getBidAnalysis,
    bidAnalysis,
    getPurchaseOrder,
    purchaseOrder,
    getOrder,
    order,
    selectedGood,
    getGoodsRecieved,
    goodsRecieved,
    getRequisition,
    getSelectedGood,
  };
};

export default useRequisition;
