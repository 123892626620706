import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import ListCustomers from '../../components/cunstomers/ListCustomers'

const Customers = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Customers' />
        <ListCustomers />
      </>
    </DashboardWrapper>
  )
}

export default Customers